.wrapper {
	display: grid;
	gap: 1rem;
}

.linesWrapper {
	display: flex;
	flex-direction: column;
}

.companyCard {
	margin-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid;
	display: grid;
	gap: 1rem;
}

.companyName {
	font-size: 1.5rem;
	color: var(--activeLink);
	print-color-adjust: exact !important;
	font-weight: 400;
}

@media print {
	.companyCard {
		margin-top: 1em;
		padding-bottom: 1em;
		border: none;
		gap: 0.5em;
	}

	.companyName {
		font-size: 2em;
		color: var(--text);
		page-break-after: avoid;
	}

	.noPrint {
		display: none;
	}
}
