.base {
	z-index: 100;
	position: fixed;
	top: 22vh;
	left: 10%;
	width: 80%;
	background: white;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 8px;
}

.header {
	padding: 1rem 0.5rem;
	background-color: var(--StandardBgColor);
	/* background: #2a006e; */
	color: white;
}

.header h2 {
	margin: 0.5rem;
}

.content {
	padding: 1rem 0.5rem;
	overflow: auto;
}

.footer {
	padding: 1rem 0.5rem;
}

.danger {
	background-color: var(--DangerBgColor);
}

/* @media (min-width: 768px) {
	.base {
		left: calc(50% - 20rem);
		width: 40rem;
	}
} */

.base-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.base-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms;
}

.base-exit {
	transform: translateY(0);
	opacity: 1;
}

.base-exit-active {
	transform: translateY(-10rem);
	opacity: 0;
	transition: all 200ms;
}

@media(max-width: 768px){
	.base{
	left: 2%;
	top: 2%;
	width: 96%;
	height: 96%;
	display: flex;
	flex-direction: column;
}
.base form {
	display: flex;
	flex-direction: column	;
	overflow: auto;
	justify-content: space-around;
	align-items: center;
}
}