:root {
	/* Main colotos */
	--ONGOING: hsl(100, 50%, 50%);
	--ENDED: hsl(0, 0%, 80%);
	--ENDEDtext: hsl(0, 0%, 60%);
	--PAUSED: hsl(51, 50%, 50%);
	--STOPED: hsl(0, 50%, 50%);
	--TODO: hsl(180, 50%, 50%);
	--ALERT: hsl(51, 90%, 50%);

	/* App commons */
	/* #4F4319 */
	/* --bgLight: lightgrey; */
	--bgLight: hsl(0, 0%, 94%);
	--bgColor: hsl(30, 50%, 15%);
	--higlight: hsl(30, 70%, 37%);
	--contrast: white;
	--text: hsl(30, 53%, 15%);
	--activeLink: hsl(30, 85%, 54%);
	--activeLinkLight: hsl(193, 100%, 95%);
	--backdrop: hsla(30, 53%, 15%, 0.6);
	--error: var(--STOPED);

	/* Elemetns */
	--StandardBgColor: var(--activeLink);
	--DangerBgColor: var(--STOPED);

	/* Button */
	--ButtonStdBgColor: var(--activeLink);
	--ButtonStdTxtColor: var(--bgColor);
	--ButtonDngrBgColor: var(--STOPED);
	--ButtonDngrTxtColor: var(--contrast);

	/* --ABORTED: hsl(0, 0%, 48%); */
	/* --COMPLETED: hsl(0, 50%, 50%); */
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
a {
	all: unset;
}

html {
	max-height: 100vh;
	max-height: 100svh;
	overflow: hidden;
	line-height: 1.5rem;
	font-size: 18px;
}

.App {
	display: flex;
	/* height: 100vh; */
	height: 100svh;
	width: 100svw;
	overflow: hidden;
}

.menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: fit-content;
	background-color: var(--bgColor);
	color: var(--contrast);
}

.menu__element {
	font-size: 1.5rem;
	font-weight: bold;
	padding: 1rem;
	margin: 2rem 1rem;
	border-radius: 6px;
}
.menu__element:hover {
	/* background-color: #dbb939; */
	/* box-shadow: 2px 2px 5px black; */
	text-decoration: underline;
	/* color: white; */
}

.content {
	width: 100%;
	min-width: 0px;
	max-width: 100vw;
	height: 100%;
	padding: 0.5rem;
	background-color: var(--bgLight);
	overflow: hidden;
	display: flex;
}

@media (max-width: 768px) {
	.hide {
		display: none;
	}
	/* .content {
		padding-bottom: 4rem;
	}
	.menu {
		padding-bottom: 2rem;
	} */
}

/* .App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}


@keyframes App-logo-spin {
	from {
		rotate: 0deg;
	}
	to {
		rotate: 360deg;
	}
} */

/* Fixme: Valutare effettiva necessità */
@media print {
	.App {
		height: fit-content;
		background-color: unset;
	}
	.content {
		overflow: unset;
		break-inside: auto;
		height: fit-content;
		background-color: unset;
		padding: 0;
	}
}
