.wrapper {
	background-color: var(--contrast);
	width: 100%;
	display: flex;
	flex-direction: column;
	font-weight: 200;
	cursor: pointer;
}

.image {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
}
.image img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.data {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	line-height: 1.5;
}

.siteName {
	font-weight: 900;
	color: var(--activeLink);
	text-transform: uppercase;
	font-size: 1.5rem;
	text-align: center;
	line-height: 1.2;
	margin-bottom: 1rem;
}

.committente {
	font-weight: 400;
}

.description {
	padding-top: 1rem;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0.5rem;
	font-size: 2rem;
	color: var(--activeLink);
}
