.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.content {
	width: 100%;
}

.contentPrint {
	display: none;
	padding: 0.5rem;
}

.buttons {
	font-size: 2rem;
	color: var(--activeLink);
}

.disabled {
	color: var(--ENDEDtext);
}

@media print {
	.buttons {
		display: none;
	}

	.content {
		display: none;
	}

	.contentPrint {
		display: block;
	}
}
