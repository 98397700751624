.wrapper {
	width: 100%;
	padding: 2rem;
	font-weight: 200;
	overflow: auto;
}

.header {
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.body {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.saveDisabled {
	display: none;
}

.saveEnabled {
	font-size: 2rem;
	color: var(--ONGOING);
	background-color: var(--contrast);
	width: fit-content;
	position: absolute;
	bottom: 1rem;
	right: 1.5rem;
	padding: 0.5rem;
	border: 1px solid var(--bgColor);
	border-radius: 10rem;
	box-shadow: 0 0 4px;
}

@media (max-width: 768px) {
	.wrapper {
		padding: 0;
	}

	.header {
		flex-direction: column;
		gap: 0;
	}

	.saveEnabled {
		bottom: 1rem;
		right: 1rem;
	}
}
