.wrapper {
	border: 1px solid;
	padding: 1rem;
}

.imageWrapper {
}
.imageWrapper img {
}

.addImage {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 1rem;
	color: var(--activeLink);
}

.addImage > img {
	width: 50%;
}

.imgButtons {
	display: flex;
	align-items: center;
	gap: 4rem;
}

.imgDescription {
	text-align: center;
}

.buttons {
	font-size: 2rem;
}
