.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.action {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
	padding: 1.5rem 1rem;
	border: 1px solid var(--bgColor);
	background-color: var(--activeLink);
	color: var(--contrast);
	box-shadow: 1px 2px 3px var(--bgColor);
	border-radius: 10px;
	min-width: 200px;
}

.action:hover {
	background-color: var(--bgColor);
	box-shadow: 1px 2px 3px var(--activeLink);
}

.buttons {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.insert {
	width: 50%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.insertButton {
	font-size: 2rem;
	padding: 1rem;
	color: var(--activeLink);
	background-color: var(--contrast);
	box-shadow: 1px 2px 3px var(--bgColor);
	border: 1px solid var(--activeLink);
	border-radius: 10px;
}

.insertButton:hover {
	cursor: pointer;
	/* color: var(--bgColor);
	border: 1px solid var(--bgColor); */
	box-shadow: 2px 3px 4px var(--bgColor);
}

.previusRecords {
	margin-top: 0.5rem;
	padding: 0 1rem;
}

.prevRecCount {
	font-size: 12px;
	position: absolute;
	background-color: var(--activeLink);
	color: var(--contrast);
	border-radius: 6px;
	width: 16px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -32px;
	margin-left: 36px;
}

.todayRecords {
	width: 30%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 10px;
	padding: 2%;
}

.currentTot {
	width: 100%;
	text-align: center;
	font-size: 1.4rem;
	padding-top: 0.5rem;
}

.dayRecord {
	text-align: center;
	width: 50%;
}

.hide {
	visibility: hidden;
}

@media (max-width: 768px) {
	.todayRecords {
		width: 80%;
	}

	.insert {
		width: 100%;
	}
}
