.wrapper {
	width: 100%;
	padding: 2rem;
	font-weight: 200;
	overflow: auto;
	line-height: 1;
}

.group {
	display: flex;
	flex-direction: column;
}
.group p {
	font-weight: 700;
	margin-bottom: 0.5rem;
}

.types,
.models {
	display: flex;
	gap: 2rem;
	align-items: center;
	/* justify-content: space-between; */
	margin-bottom: 1rem;
	font-size: 2rem;
}

.tCard {
	background-color: var(--contrast);
	border: 1px solid var(--text);
	padding: 1rem 2rem;
}

.mCard {
	background-color: var(--contrast);
	border: 1px solid var(--text);
	padding: 1rem 2rem;
}

.selected {
	background-color: var(--activeLink);
}

.notSelected {
	font-weight: 900;
	color: var(--STOPED);
}
