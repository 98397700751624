.reportCard {
	padding: 1rem;
	border: 1px solid;
	cursor: pointer;
}

.reportCard:hover {
	background-color: var(--activeLink);
	color: var(--contrast);
}
