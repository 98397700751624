.wrapper {
	padding-bottom: 1rem;
	border-bottom: 1px solid;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	border: 1px solid;
	background-color: var(--contrast);
}

.header {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	font-weight: 600;
	/* gap: 2rem; */
	line-height: 1.5;
	text-decoration: underline;
	margin-bottom: 1rem;
}

.contWrapper {
	/* display: flex;
	flex-direction: column; */
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
}

@media (max-width: 768px) {
	.contWrapper {
		grid-template-columns: 1fr;
	}
}
