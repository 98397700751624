.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding: 1rem 0;
}

.header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 1.2rem;
	font-weight: 900;
	color: var(--bgColor);
	margin-bottom: 1rem;
	border-bottom: 2px solid var(--activeLink);
	text-wrap: nowrap;
}

.body {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.findCompanyWrapper {
	display: flex;
	align-items: center;
}

.descriptionWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.descLabel {
	font-weight: 700;
}

.description {
	resize: vertical;
}

@media (max-width: 768px) {
	.body {
		gap: 0.5rem;
	}
}
