.wrapper {
	width: 100%;
	padding: 2rem;
	font-weight: 200;
	overflow: auto;
}

.header {
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	font-size: 2rem;
}

.body {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

@media (max-width: 768px) {
	.wrapper {
		padding: 0.5rem;
	}
}

@media print {
	.wrapper {
		padding: 0;
		font-size: 11px;
	}

	.header {
		display: none;
	}
}
