.background {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: var(--backdrop);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

.wrapper {
	position: absolute;
	top: 12%;
	left: 25%;
	height: 76%;
	width: 50%;
	background-color: var(--bgLight);
	border: 1px solid var(--higlight);
	border-radius: 10px;
	box-shadow: 2px 2px 2px var(--bgColor);
	padding: 2%;
	z-index: 60;
	overflow: auto;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.card {
	margin: 0.5rem;
	padding: 0.5rem;
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 10px;
}

@media (max-width: 768px) {
	.wrapper {
		top: 8%;
		left: 5%;
		height: 84%;
		width: 90%;
	}
}
