.error {
	color: var(--STOPED);
	font-weight: 900;
}

.employees {
	padding: 0 0.5rem;
	display: grid;
	gap: 0.5rem;
}

.referentCard {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid;
	/* margin-bottom: 1rem; */
}

.buttons {
	font-size: 1.2rem;
	color: var(--higlight);
}

.addButtons {
	color: var(--activeLink);
	font-size: 1.5rem;
	display: flex;
	justify-content: space-between;
}

.addReferentWrapper {
	display: flex;
	align-items: center;
}
