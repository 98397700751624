.background {
	position: absolute;
	top: 0;
	left: 0;
	height: 100svh;
	width: 100svw;
	background-color: var(--backdrop);
	color: var(--bgColor);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

.wrapper {
	position: absolute;
	top: 20%;
	left: 25%;
	height: fit-content;
	width: fit-content;
	background-color: var(--bgLight);
	border: 1px solid var(--higlight);
	box-shadow: 0px 0px 20px var(--bgColor);
	padding: 2%;
	z-index: 60;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.canvas {
	border: 1px solid black;
	background-color: white;
}

@media (max-width: 1000px) {
	.wrapper {
		top: 2%;
		left: 2%;
		height: 96%;
		width: 96%;
	}
}
