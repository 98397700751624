.wrapper {
	width: 96%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 1.5% 0;
}

.filters {
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	height: fit-content;
	width: 100%;
}

.filtersHandler {
	display: none;
}

@media (max-width: 768px) {
	.container {
		flex-direction: column;
		padding: 1.5% 0;
	}
	.filters {
		flex-direction: column;
	}

	.filtersHandler {
		display: block;
		width: 100%;
		text-align: right;
		font-weight: bold;
		text-decoration: underline;
		padding: 1% 0;
	}

	.inputs__hidden {
		display: none;
	}
}
