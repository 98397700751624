.wrapper {
	padding: 0.5rem 0;
	border-bottom: 1px solid;
	display: grid;
	gap: 0.5rem;
}

.title {
	font-size: 1.2em;
	font-weight: 400;
	color: var(--ENDEDtext);
	text-decoration: underline;
}
