.wrapper {
	padding-bottom: 0.5em;
	line-height: 1.5;
}

.title {
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: underline;
	color: var(--ENDEDtext);
}

.grid3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding-left: 1em;
	border-left: 4px solid var(--activeLink);
}

.name {
	font-size: 1.2em;
	font-weight: 400;
}
