.wrapper {
	display: flex;
	border: 1px solid var(--ENDED);
	break-inside: avoid;
}

.hidden {
	/* display: none; */
}

.imageWrapper {
	width: 30%;
	display: grid;
	border-right: 1px solid var(--ENDED);
}
.image {
	position: relative;
	width: 100%;
	/* padding-bottom: 75%; */
}

.image img {
	/* position: absolute; */
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.lineHeader {
	font-size: 1.2rem;
	font-weight: 400;
	text-decoration: underline;
}

.textWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
	padding: 1rem;
	width: 70%;
}

.revisionDate {
	font-weight: 500;
	color: var(--STOPED);
}

.noPicture {
	width: 100%;
}

.buttons {
	font-size: 2rem;
	display: flex;
	justify-content: flex-end;
	gap: 2rem;
}

@media (max-width: 768px) {
	.wrapper {
		flex-direction: column;
	}

	.imageWrapper {
		width: 100%;
		display: grid;
		border-right: 1px solid var(--ENDED);
	}
}

@media print {
	.wrapper {
		border: none;
		border-bottom: 1px solid var(--ENDED);
		flex-direction: row;
	}

	.imageWrapper {
		width: 20%;
		border: none;
	}

	.textWrapper {
		gap: 1em;
		padding: 1em;
	}

	.lineHeader {
		font-size: 1.2em;
	}

	.buttons {
		display: none;
	}
}
