.wrapper {
}

.header {
	font-weight: 400;
	font-size: 1.2rem;
	margin-bottom: 1rem;
	text-align: center;
}

@media print {
	.wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.header {
		display: flex;
		align-items: center;
		text-align: left;
		font-size: 1.5em;
		margin-bottom: 0;
		border-right: 4px solid var(--activeLink);
		padding: 0.5em 0;
		height: 100%;
		grid-row: span 10;
		margin-right: 0.5em;
	}

	.noPrint {
		display: none;
	}
}
