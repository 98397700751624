.wrapper {
	display: grid;
}
.signatures {
	padding: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: flex-end;
	gap: 1rem;
}

.signatureWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 100%;
	border-bottom: 1px solid var(--ENDEDtext);
}

.sWrap > img {
	width: 60%;
	margin-top: 1em;
}

.name {
	font-weight: 500;
}

@media (max-width: 768px) {
	.wrapper {
		grid-template-columns: 1fr;
	}
}

@media print {
	.signatures {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.buttons {
		display: none;
	}

	.signatureWrapper {
		page-break-inside: avoid;
	}
}
