.wrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
}

.filter {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.cardsWrapper {
	/* display: flex;
	flex-wrap: wrap; */
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 2rem;
	overflow: auto;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width: 1240px) {
	.cardsWrapper {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 768px) {
	.wrapper {
		gap: 0.5rem;
	}
	.buttons {
		justify-content: center;
		font-size: 1rem;
	}
	.cardsWrapper {
		grid-template-columns: 1fr;
	}
}
