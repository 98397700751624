.wrapper {
	margin-top: -1em;
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding: 0 1rem;
}

.committenteWrapper {
	padding-bottom: 0.5em;
	border-bottom: 1px solid var(--ENDED);
}

.contactsWrapper {
	display: flex;
	flex-direction: column;
	gap: 1em;
}
.segmentTitle {
	margin-top: 1em;
	font-weight: 900;
	text-transform: uppercase;
	color: var(--ENDED);
	padding-bottom: 0.5em;
}
