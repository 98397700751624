.wrapper {
	width: 100%;
	border: 1px solid;
	padding: 1rem;
}

.header {
	font-size: 1.2rem;
	font-weight: 900;
	color: var(--bgColor);
	/* text-decoration: underline; */
	margin-bottom: 1rem;
	border-bottom: 2px solid var(--activeLink);
	width: fit-content;
}

.body {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.subtitle {
	text-transform: uppercase;
	/* text-decoration: underline; */
	/* border-bottom: 1px solid var(--higlight); */
	border-bottom: 1px solid var(--activeLink);
	width: fit-content;
}

.details {
	padding: 0 0.5rem;
}

.details {
	border-right: 1px solid;
}

.details div {
	margin-bottom: 0.3rem;
}

.appaltatore {
	padding-left: 0.5rem;
	/* font-size: 1.1rem; */
	font-weight: 400;
}

@media (max-width: 768px) {
	.body {
		grid-template-columns: 1fr;
	}

	.details {
		border-right: 0;
		border-bottom: 2px solid var(--activeLink);
	}
}
