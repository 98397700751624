.wrapper {
	display: flex;
	flex-direction: column;
	line-height: 1.2;
	gap: 1rem;
}

.boxed {
	background-color: var(--contrast);
	padding: 0.5rem;
	border: 1px solid var(--text);
}

.title {
	font-size: 1.2rem;
	font-weight: 900;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
	text-decoration: underline;
	color: var(--activeLink);
}

.header {
	font-size: 1.6rem;
	text-align: center;
	color: var(--activeLink);
	font-weight: 900;
	text-transform: uppercase;
}

.headerDescription {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hTitle {
	text-align: center;
}

.editForm {
	display: flex;
	align-items: center;
}

.operator {
	text-align: right;
}

.bold {
	font-weight: 500;
}

.accent {
	color: var(--activeLink);
	font-weight: 900;
}

.buttons {
	font-size: 3rem;
	color: var(--activeLink);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	gap: 2rem;
}
.buttons > span {
	background-color: var(--contrast);
	padding: 1rem;
	border: 2px solid;
}

.SiteMainData {
	display: none;
}

.footer {
	display: none;
}

@media (max-width: 768px) {
	.headerDescription {
		flex-direction: column;
		gap: 1rem;
	}
	.header {
		font-size: 1.5rem;
	}

	.operator {
		text-align: center;
	}
}

@media print {
	.wrapper {
		gap: 0em;
	}

	.boxed {
		border: 0;
		padding-bottom: 1em;
		margin-bottom: 1em;
		border-bottom: none;
	}

	.headerDescription {
		flex-direction: row;
		border-bottom: 1px solid var(--ENDED);
		/* position: fixed;
		width: 100%; */
	}

	.header,
	.title,
	.accent {
		font-size: 1.2em;
		font-weight: 500;
		color: var(--text);
	}

	.title {
		font-size: 1.5em;
		font-weight: 500;
		margin-top: 1em;
		color: var(--ENDEDtext);
		page-break-after: avoid;
	}

	.header {
		font-size: 2em;
	}

	.operator {
		text-align: right;
	}

	.suspended {
		display: none;
	}

	.commessa > .title {
		margin-top: 0;
	}

	.SiteMainData {
		display: block;
		/* page-break-after: always; */
	}

	.presence > .title {
		margin-top: 0;
	}

	/* .activeWorks {
		page-break-after: always;
	} */

	.finalProvisions,
	.signatures {
		page-break-inside: avoid;
	}

	.finalProvisions > .title {
		margin-top: 0;
	}

	.noPrint {
		display: none;
	}
}
