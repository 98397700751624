.wrapper {
	width: 80%;
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 10px;
	padding: 1rem;
}

.header {
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	padding-bottom: 1rem;
	gap: 2rem;
}

.roleWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 2rem;
}

.subTitle {
	padding-top: 1rem;
	grid-column: 1/5;
	text-align: center;
	font-size: 1.5rem;
	line-height: 1.5;
	border-bottom: 1px solid;
}

.card {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-weight: 200;
	border-left: 3px solid;
	cursor: pointer;
}

.card:hover {
	color: var(--activeLink);
}

.cardTitle {
	font-weight: 200;
}
