.wrapper {
	padding-bottom: 1rem;
	border-bottom: 1px solid;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	border: 1px solid;
	background-color: var(--contrast);
}

.header {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	font-weight: 600;
	/* gap: 2rem; */
	line-height: 1.5;
	text-decoration: underline;
}

.addElement {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.target {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
}

.targetData {
	line-height: 1.5;
}
.targetName {
	font-size: 1.2rem;
	font-weight: 500;
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	justify-content: space-between;
}
