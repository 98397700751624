.section {
	display: grid;
	gap: 1rem;
}

.panelsWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

@media print {
	.panelsWrapper {
		display: grid;
	}
}
