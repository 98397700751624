.wrapper {
	width: 80%;
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 10px;
	padding: 1rem;
}

.header {
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	padding-bottom: 1rem;
}

.header__add {
	padding: 0 2rem;
}

.header__add:hover {
	color: var(--ONGOING);
	text-shadow: 1px 1px 2px black;
}

.list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
}

.row {
	display: flex;
	width: 20rem;
	cursor: help;
	padding-bottom: 0.4rem;
}

.disabled {
	text-decoration: line-through;
}

.row__edit {
	padding-right: 0.4rem;
}

.row__edit:hover {
	color: var(--activeLink);
	text-shadow: 1px 1px 2px black;
}
