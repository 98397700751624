.wrapper {
	padding: 0.5rem;
	/* border: 1px solid; */
	margin-bottom: 0.2rem;
	user-select: none;
	box-shadow: 0 0 2px var(--bgColor);
}

.header {
	font-weight: 400;
	font-size: 1.2rem;
}

.active {
	background-color: var(--activeLink);
	/* print-color-adjust: exact; */
}

@media print {
	.wrapper {
		box-shadow: none;
		padding: 0.5em;
		margin-right: 0.5em;
		display: none;
	}

	.header {
		font-size: 1.2em;
	}

	.state {
		display: none;
	}

	.active {
		display: block;
	}
}
