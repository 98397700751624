input {
	font-size: inherit;
	padding: 0.5rem;
}

.formCtrl {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: fit-content;
	width: 45%;
	font-size: inherit;
	font-weight: bold;
}

.formCtrl__small {
	width: 20%;
}

textarea {
	width: 80%;
	height: 5.1rem;
	max-width: 100%;
	font-size: inherit;
	padding: 0.5%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.errorText {
	color: var(--error);
	font-weight: bold;
}

.checkbox {
	scale: 1.5;
	/* margin-top: 5px; */
}

.radio {
	width: 100%;
}

.radio_container {
	display: flex;
	align-items: center;
	gap: 1rem;
	/* width: 50%; */
}

.radio1 {
	transform: scale(2);
}

.radio_dot {
	display: flex;
	align-items: center;
	font-weight: 200;
	gap: 4rem;
}
.radio_label {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
}

.dropdownTime {
	display: flex;
	border: solid 1px red;
	background-color: chartreuse;
}

.dropdownTime_hours {
	width: 25%;
}

.noListData {
	box-shadow: 0px 0px 10px var(--DangerBgColor);
	border: 1px solid var(--DangerBgColor);
	background-color: var(--DangerBgColor);
}

.hours {
	width: 90%;
}

.quarters {
	width: 90%;
}

@media (max-width: 768px) {
	.radio {
		width: 100%;
	}

	.radio_dot {
		font-size: 24px;
		align-items: flex-start;
		flex-direction: column;
		gap: 0;
	}

	textarea {
		height: 10rem;
	}
	.formCtrl {
		width: 100%;
	}

	.errorText {
		text-align: center;
		width: 100%;
	}
}

.hidden {
	display: none;
}
