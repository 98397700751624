.wrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
}

.filter {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.cardsWrapper {
	display: flex;
	flex-wrap: wrap;
	/* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
	gap: 2rem;
	overflow: auto;
}

.subTitle {
	padding-top: 1rem;
	/* grid-column: 1/7; */
	text-align: center;
	font-size: 1.5rem;
	line-height: 1.5;
	border-bottom: 1px solid;
	width: 100%;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
