.container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: var(--backdrop);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

.content {
	position: absolute;
	top: 10%;
	left: 15%;
	height: 80%;
	width: 70%;
	background-color: var(--bgLight);
	border: 1px solid var(--higlight);
	border-radius: 10px;
	box-shadow: 2px 2px 2px var(--bgColor);
	padding: 2%;
	z-index: 60;
	overflow: auto;
	display: flex;
}

.form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	overflow: auto;
}

@media (max-width: 768px) {
	.content {
		top: 5%;
		left: 5%;
		height: 90%;
		width: 90%;
		flex-direction: column;
	}

	.form {
		overflow: unset;
		padding-bottom: 150px;
	}
}
