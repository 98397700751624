.weekStructure {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.weekStructure__days {
	width: 60%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	padding: 1rem;
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 10px;
	margin-bottom: 1rem;
}
.weekStructure__days__day {
	width: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.weekStructure__days {
		width: 100%;
		padding: 1rem 0;
	}
}
