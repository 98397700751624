.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: auto;
	/* padding: 0.1rem; */
}

.header {
	display: flex;
	align-items: center;
	font-size: 2rem;
	/* text-decoration: underline; */
}

.navigation {
	display: none;
	text-decoration: none;
	color: inherit;
}

.navigation:hover {
	color: var(--contrast);
}

.filters {
	visibility: visible;
}

.attendance {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-around;
	row-gap: 1rem;
	overflow: auto;
}

.totalsWrapper {
	margin-top: 2rem;
	/* break-after: always; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.empWrapper {
	/* overflow: auto; */
	width: auto;
}

.employeeCard {
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 10px;
	padding: 1%;
	width: 45%;
	min-width: fit-content;
}

.totals {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	background-color: var(--contrast);
	border: 1px solid;
	border-radius: 10px;
	padding: 1rem;
}

.totEl {
	text-align: center;
}

.employeeCardHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	font-size: larger;
	font-weight: bold;
	/* padding-bottom: 1rem; */
}

.employeeCardHeader__Name {
	text-decoration: underline;
	font-size: 2rem;
}

.employeeCardHeader__Print {
	font-size: 40px;
	padding: 1%;
	border: 1px solid transparent;
}

.employeeCardHeader__Print:hover {
	background-color: var(--ButtonStdBgColor);
	color: var(--contrast);
	border-radius: 200px;
	border: 1px solid var(--bgColor);
}

.cardRows {
	padding-top: 1%;
	/* min-width: max-content; */
}

.dailyRow {
	display: flex;
	border-bottom: 1px solid var(--bgColor);
	padding: 0.15rem;
}

.day0 {
	/* background-color: var(--higlight); */
	background-color: var(--activeLink);
	/* color: var(--contrast); */
	print-color-adjust: exact;
}
.day6 {
	background-color: var(--bgLight);
	/* color: var(--contrast); */
	print-color-adjust: exact;
}

.dailyRowHeader {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	min-width: fit-content;
	width: 100%;
	font-weight: bold;
	text-transform: uppercase;
}

.dailyDate {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-width: 120px;
}

.dailyTime {
	display: flex;
	/* width: 100%; */
	align-items: center;
	justify-content: flex-start;
	width: 300px;
	flex-wrap: wrap;
	gap: 8px;
}

.dailyTime_time {
	min-width: fit-content;
	/* margin-right: 10px; */
	/* font-size: 0.8rem; */
}

.manualInput {
	color: var(--higlight);
	text-decoration: underline;
	font-weight: 500;
}

.officeInput {
	text-decoration: underline;
}

.dailyTime_time:hover {
	text-shadow: 0 0 1px var(--STOPED);
	text-decoration: underline;
	cursor: pointer;
}

.totRow {
	/* padding: 0 6px; */
	min-width: 80px;
	text-align: center;
	align-self: center;
}

.totRowHidden {
	visibility: hidden;
}

.totRowError {
	color: var(--STOPED);
	font-weight: bold;
}

.justification {
	/* color: var(--STOPED);
	font-weight: bold; */
	width: 100px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.justification:hover {
	text-decoration: underline;
	cursor: pointer;
}

.totRow__desc {
	display: none;
}

.insertJustiification {
	background-color: var(--STOPED);
	color: var(--contrast);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	cursor: pointer;
	print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
}

.addNewRecord {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 24px;
}
.addNewHeader {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.addNewRecord:hover {
	color: var(--ONGOING);
	text-shadow: 1px 1px 2px black;
	/* background-color: var(--ButtonStdBgColor); */
	border-radius: 500px;
}

.archiveBtnWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

@media (max-width: 768px) {
	.container {
		flex-direction: column;
	}

	.navigation {
		display: unset;
	}

	.attendance {
		justify-content: unset;
		align-items: center;
		width: 100%;
	}

	.empWrapper {
		width: 100%;
	}

	.employeeCard {
		width: 100%;
	}

	.employeeCardHeader__Print {
		display: none;
	}

	.cardRows {
		max-width: 100%;
	}

	.dailyRowHeader {
		display: none;
	}

	.dailyRow {
		flex-direction: column;
		align-items: center;
	}
	.dailyDate {
		min-width: fit-content;
	}

	.dailyTime {
		justify-content: center;
	}

	.justification {
		width: unset;
	}

	.insertJustiification {
		width: 3rem;
	}

	.totRow__desc {
		display: unset;
		font-weight: bold;
	}

	.totRow {
		min-width: fit-content;
	}

	.totRowHidden {
		display: none;
	}
}

@media print {
	.container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0;
		/* page-break-inside: auto; */
		overflow: visible;
		z-index: 100;
	}

	.header,
	.navigation,
	.filters {
		display: none;
	}

	.employeeCardHeader__Print {
		visibility: hidden;
	}

	.dailyRowHeader {
		display: unset;
	}

	.dailyRow,
	.totEl {
		flex-direction: row;
		align-items: flex-start;
		font-size: 11px;
		line-height: 2;
		height: fit-content;
		padding: 0;
	}

	.attendance {
		overflow: visible;
		background-color: white;
		margin: 0;
		width: 100%;
		/* height: 100%; */
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
	}

	.totalsWrapper {
		margin-top: 0.5rem;
	}

	.employeeCard {
		background-color: white;
	}
	/*  */
	.empWrapper {
		width: auto;
	}

	.employeeCard {
		width: 90%;
	}

	.employeeCardHeader__Print {
		display: none;
	}

	.cardRows {
		max-width: 100%;
	}

	.dailyRowHeader {
		display: none;
	}

	.dailyDate {
		min-width: 80px;
	}

	.dailyRowHeader {
		display: flex;
		font-size: 10px;
	}

	.dailyTime {
		justify-content: flex-start;
		width: 200px;
	}

	.justification {
		width: 60px;
	}

	.totRow__desc {
		display: none;
	}

	.totRow {
		min-width: 60px;
	}

	.totRowHidden {
		display: flex;
		width: 50px;
	}

	.addNewHeader {
		display: none;
	}
	.addNewRecord {
		display: none;
	}

	.archiveBtnWrapper {
		display: none;
	}
	/*  */
}
