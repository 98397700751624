.wrapper {
	padding-bottom: 1rem;
	border-bottom: 1px solid;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	border: 1px solid;
	background-color: var(--contrast);
}

.header {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	font-weight: 600;
	/* gap: 2rem; */
	line-height: 1.5;
	text-decoration: underline;
}

.dataWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
}

.siteDataWrapper {
	display: flex;
	gap: 2rem;
	width: 100%;
}

.siteData {
	width: 50%;
	display: flex;
	flex-direction: column;
	line-height: 1.5;
	gap: 1rem;
}

.title {
	font-size: 1.2;
	width: fit-content;
	border-bottom: 1px solid;
	color: var(--activeLink);
	font-weight: 400;
}

.data {
	width: 50%;
}

.siteName {
	font-size: 1.5rem;
	font-weight: 900;
	color: var(--activeLink);
	text-transform: uppercase;
}

/*--------------------------
Immagine 
---------------------------*/

.imgWrapper {
	width: 50%;
	display: flex;
}

.image {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
}

.image img {
	position: absolute;
	width: 100%;
	height: 100%;
	/* object-fit: cover; */
	object-fit: contain;
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	justify-content: space-between;
}

@media (max-width: 768px) {
	.wrapper {
		padding: 0;
	}
	.siteDataWrapper {
		flex-direction: column-reverse;
		gap: 0;
	}
	.imgWrapper {
		width: 100%;
	}

	.siteData {
		width: 100%;
		padding: 0.5rem;
	}
}
