.background {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: var(--backdrop);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 60;
}

.wrapper {
	position: absolute;
	top: 20%;
	left: 15%;
	height: 60%;
	width: 70%;
	background-color: var(--bgLight);
	border: 1px solid var(--higlight);
	border-radius: 10px;
	box-shadow: 2px 2px 2px var(--bgColor);
	padding: 2%;
	z-index: 71;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.form {
	width: 100%;
	display: flex;
	/* flex-direction: column; */
	align-items: center;
	justify-content: space-around;
	overflow: auto;
}

.form__entrance {
	flex-direction: column;
}

.form__buttons {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.displayEntrance {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
}
.dayEntrance {
	padding: 0.5rem;
	min-height: 140px;
	min-width: 84px;
	background-color: var(--contrast);
	border: 1px solid var(--bgColor);
	border-radius: 4px;
	box-shadow: 1px 1px 3px var(--bgColor);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
.entrance {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	text-align: right;
}

.entranceDelete {
	/* display: flex;
	align-items: center; */
	color: var(--STOPED);
}

.entranceAdd {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.entranceAdd__button {
	font-size: 1.4rem;
	color: var(--ONGOING);
}

.entranceAdd__input {
	text-align: center;
	width: 80px;
	padding: 0;
}

.hidden {
	visibility: hidden;
}

@media (max-width: 768px) {
	.wrapper {
		top: 1%;
		left: 2%;
		height: 98%;
		width: 96%;
	}

	.form {
		flex-direction: column;
		align-items: center;
	}
}
