.wrapper {
	padding: 0.5rem 0;
	border-bottom: 1px solid;
}

.header {
	display: flex;
	align-items: center;
}

.buttons {
	margin-left: 2rem;
	font-size: 2rem;
	color: var(--activeLink);
}

.company {
	font-weight: 700;
	font-size: 1rem;
}

.standard {
	background-color: var(--TODO);
}

.alert {
	/* background-color: var(--PAUSED); */
	background-color: var(--ALERT);
	color: var(--STOPED);
	padding: 0.1rem 0.5rem;
	font-weight: 500;
}

.danger {
	background-color: var(--STOPED);
	color: var(--contrast);
	padding: 0.1rem 0.5rem;
	font-weight: 500;
}

.closed {
	background-color: var(--ONGOING);
	padding: 0.1rem 0.5rem;
}

@media (max-width: 768px) {
	.header {
		justify-content: space-between;
	}
}
