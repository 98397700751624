.wrapper {
	padding-bottom: 1rem;
	border-bottom: 1px solid;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	border: 1px solid;
	background-color: var(--contrast);
}

.header {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	font-weight: 600;
	/* gap: 2rem; */
	line-height: 1.5;
	text-decoration: underline;
}

.reportWrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 2rem;
}

.reports {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}
