.wrapper {
	display: grid;
	grid-template-columns: 1fr 3fr;
	/* padding-bottom: 0.5em; */
	border-bottom: 1px solid var(--ENDED);
	gap: 1rem;
}

.companyName {
	font-size: 1.2em;
	font-weight: 400;
}

.header {
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: underline;
	color: var(--ENDEDtext);
	margin-bottom: 0.5em;
}
